import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Layout from "../../../components/Layout";
import Seo from "../../../components/Seo";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Station from "../../../modules/about/station/Station";

const Page = ({ location, ...props }) => (
  <StaticQuery
    query={graphql`
      query GetStaticMap {
        staticMap {
          childFile {
            childImageSharp {
              gatsbyImageData(width: 1024, height: 1024)
            }
          }
        }
      }
    `}
    render={({ staticMap }) => (
      <>
        <Seo
          title="Gerätehaus | Über uns"
          pathname={location.pathname}
          description="Alles über die Räumlichkeiten rund um unser Gerätehaus in Arborn, zur Bollerbrücke 6."
        />

        <Layout>
          <Breadcrumbs names={["Über uns", "Gerätehaus"]} location={location} />
          <Station staticMap={staticMap} {...props} />
        </Layout>
      </>
    )}
  />
);

export default Page;
