/** @jsxImportSource theme-ui */
import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { BaseStyles } from "theme-ui";

const Station = ({ staticMap }) => (
  <BaseStyles>
    <div
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
      }}
    >
      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          flex: (t) => `1 1 ${t.sizes.container}`,
        }}
      >
        <div
          sx={{
            maxWidth: "container",
          }}
        >
          <h3>Gerätehaus</h3>
          <p>
            Das Feuerwehrgerätehaus Arborn, welches 1993 gebaut wurde, befindet
            sich in der Straße „Zur Bollerbrücke 6“ unterhalb des{" "}
            <abbr title="Dorfgemeinschaftshaus">DGH</abbr>.
          </p>
          <p>
            Im Jahre 2016 wurde eine zusätzliche Garage neben dem bestehenden
            Gerätehaus zur Unterbringung unseres Mannschaftstransportfahrzeugs
            (MTF) errichtet, um über ausreichend Platz in den Garagen zu
            verfügen.
          </p>
          Räume:
          <ul>
            <li>Garage mit 2 Stellplätzen</li>
            <li>Neubau: Garage mit 1 Stellplatz</li>
            <li>Umkleideraum</li>
            <li>Werkstatt</li>
            <li>Schulungsraum</li>
            <li>Küche</li>
            <li>Jugendfeuerwehrraum</li>
          </ul>
        </div>
      </div>
      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          flex: "1 1 320px",
        }}
      >
        {staticMap && (
          <GatsbyImage
            image={staticMap.childFile.childImageSharp.gatsbyImageData}
            alt="Karte vom Feuerwehrgerätehaus, Zur Bollerbrücke 6"
          />
        )}
      </div>
    </div>
  </BaseStyles>
);

export default Station;
